<template>
  <div v-if="groupTemp" class="flex-wrap mr-n2 pt-2" style="z-index: 1">
    <div class="gap-2 pb-2 pl-3" style="grid-template-columns: 95% 5%">
      <!--      <text-input
        :error="nameErrorText"
        v-model="groupTemp.name"
        :label="'Name'"
      />-->
      <label for-label="name"> {{ $t("Name") }}</label>
      <div class="row my-auto">
        <input
          class="form-control ml-2 text-dark w-50"
          type="text"
          v-model="groupTemp.name"
        />
        <button
          v-if="isOriginal"
          @click.prevent="$emit('deleteParentGroup', true)"
          class="cursor-pointer border-0 bg-transparent"
        >
          <feather-icon size="16" icon="TrashIcon" />
        </button>
        <button
          v-if="parent"
          @click.prevent="parent.child = null"
          class="cursor-pointer border-0 bg-transparent"
        >
          <feather-icon size="16" icon="TrashIcon" />
        </button>
      </div>
      <div v-if="nameErrorText" class="form-error text-danger">
        {{ nameErrorText }}
      </div>
      <div v-if="groupTemp.child" class="pl-4">
        <Group
          :isOriginal="false"
          :triggered="triggered"
          :parent="groupTemp"
          :group="groupTemp.child"
        />
      </div>
      <button
        @click="addSubGroup"
        v-else
        class="pl-1 py-1 mt-1 btn btn-info rounded"
      >
        + {{ $t("Add Child Group") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Group",
  props: {
    isOriginal: {
      type: Boolean,
      default: false,
    },
    triggered: {
      type: Boolean,
      default: false,
    },
    parent: {
      type: Object,
    },
    group: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    this.groupTemp = this.group;
  },
  watch: {
    groupTemp: {
      handler: function () {
        this.$emit("group", this.groupTemp);
      },
      deep: true,
    },
  },
  computed: {
    /**
     * checks if the name field length is greater than 0, if yes then trigger the error for name field
     */
    nameErrorText() {
      if (this.groupTemp.name.length == 0 && this.triggered) {
        return "Name" + this.$t("is required");
      }
      return "";
    },
  },
  methods: {
    /**
     * adds a child or a sub group to parent group
     */
    addSubGroup() {
      this.groupTemp.child = {
        name: "",
        child: null,
      };
    },
  },
  data() {
    return {
      groupTemp: null,
    };
  },
};
</script>

<style scoped>
.smartCapitalColorBlue {
  background-color: #3b82da;
  color: #fff;
}
</style>
